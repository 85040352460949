import { Component } from '@angular/core';
import { ModalFeedbackType, ModalFeedbackView } from '@trato-logistica/trato-web-ui';
import { GENERIC_CONFIRMATION_MESSAGE } from 'sharedCommon/constants/generic-error.constants';
import { ModalFeedbackBaseComponent } from '../modal-feedback-base';

@Component({
  templateUrl: '../modal-feedback-base.html',
})
export class ModalGenericErrorComponent extends ModalFeedbackBaseComponent {
  get type(): ModalFeedbackType {
    return ModalFeedbackView.ERROR;
  }

  get bodyText(): string {
    return this.data?.message || `<h4>Ops! Ocorreu um erro.</h4>
          <p>Tente novamente em alguns minutos.</p>`;
  }

  get confirmText(): string {
    return this.data?.confirmText || GENERIC_CONFIRMATION_MESSAGE;
  }
}
